// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/components/general/accueil/backgroundSelector/useBackgroundImage.tsx"
);
import.meta.hot.lastModified = "1725634057558.2644";
}
// REMIX HMR END

import { useState, useEffect } from "react";

const useBackgroundImage = () => {
  // State for the rendered and selected background image IDs
  const [temporaryBgId, setTemporaryBgId] = useState<string>("00");
  const [selectedBgId, setSelectedBgId] = useState<string>("00");

  // Check if a given image ID is the active one
  const isActiveBackgroundImage = (imgId: string) => temporaryBgId === imgId;

  // Select an image by updating the rendered ID
  const selectImage = (imgId: string) => {
    setTemporaryBgId(imgId);
  };

  // Cancel the selection, reverting the rendered ID to the selected ID
  const cancelSelection = () => {
    setTemporaryBgId(selectedBgId);
  };

  // Validate the selection, setting the selected ID to the rendered ID and saving it to localStorage
  const validateSelection = () => {
    setSelectedBgId(temporaryBgId);
  };

  return {
    temporaryBgId,
    selectedBgId,
    isActiveBackgroundImage,
    selectImage,
    cancelSelection,
    validateSelection,
  };
};

export default useBackgroundImage;
